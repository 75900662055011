@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
html,
body,
#root {
  position: relative;
  margin: 0;
  padding: 0;
  outline: none;
}

::selection {
  background: #1a1a1a;
}
::-moz-selection {
  background: #1a1a1a;
}

body {
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
  padding: 20px;
  background-color: #222222; /* #0f1010*/
}

.navigation {
  position: absolute;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  right: 3vw;
  top: 3vh;
  z-index: 99;
}

.navigation .link {
  color: #fff;
  font-size: 17px;
  margin-left: 50px;
  font-weight: 500;
}

.navigation .link:hover {
  border-bottom: 3px solid #fff;
}

.navigation .active {
  border-bottom: 3px solid #fff;
}

.overlay {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  mix-blend-mode: overlay;
  opacity: 0.5;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
}

.loading {
  position: absolute;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading h1 {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 1.3px;
  -webkit-text-stroke-color: white;
  font-size: 5vw;
  font-weight: 800;
}

@media only screen and (max-width: 600px) {
  .navigation .link {
    margin-left: 30px;
  }
  .navigation .link:hover {
    border-bottom: 2px solid #fff;
  }

  .navigation .active {
    border-bottom: 2px solid #fff;
  }
}

